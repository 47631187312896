<template>
  <div
    class="de-wrapper"
    :class="{
      'hide-many-columns': isHideManyColumns,
      'hide-one-column': isHideOneColumn,
    }"
  >
    <player-next-match
      :id="tournamentId"
      :rounds="[...rounds.winners, ...rounds.losers]"
    />
    <div
      ref="fullSizeContainer"
      :class="{ full: isFullSize }"
      class="de"
    >
      <div class="de-bracket-header">
        <BracketSlider
          v-if="winnersRounds.length"
          :key="bracketKey"
          :left-scroll="leftScroll"
          :rounds="winnersRounds"
          :style="{ fontSize: `${fontSize}px` }"
          @scroll="onBracketScroll"
        >
          <template v-if="$scopedSlots.round" #round="{ round }">
            <slot name="round" :round="round" />
          </template>
        </BracketSlider>
        <div v-if="!controlsDisabled" class="de-nav">
          <BracketSearch
            v-model="activeTeamHash"
            :tournament-id="tournamentId"
          />
          <BracketZoom v-model="fontSize" @onfullsize="onFullSize" />

          <template v-if="!controlsDisabled">
            <BracketPrintButton @click="printPage" />
            <BracketEmbed :id="tournamentId" />
            <div class="bracket-filter">
              <Select
                v-if="filters.round"
                v-model="filters.round"
                display-value="name"
                option-key="id"
                :options="filterOptions"
                @input="handleFilterInput"
              />
            </div>
          </template>
        </div>
      </div>
      <template v-if="filters.round.type !== 'bracket-bottom'">
        <div
          ref="bracket"
          class="bracket-box"
          @scroll="
            (event) => onBracketScroll(event.target.scrollLeft)
          "
        >
          <div
            class="bracket single"
            :style="{ fontSize: `${fontSize}px` }"
            @scroll="
              (event) => onBracketScroll(event.target.scrollLeft)
            "
          >
            <div
              v-for="(round, index) in filteredWinners"
              :key="index"
              class="bracket-row"
            >
              <template
                v-if="round.matches.length && !hideWinners(index)"
              >
                <div
                  v-for="(match, key) in round.matches"
                  :key="key"
                  class="bracket-col"
                >
                  <slot
                    name="match"
                    :match="{ ...match, activeTeamHash }"
                  >
                    <match-box
                      :match="match"
                      :order="index ? null : key + 1"
                      :active-hash="activeTeamHash"
                    />
                  </slot>
                  <div
                    class="line"
                    :class="{
                      active: activeTeamId === match.idWinner,
                    }"
                  ></div>
                </div>
              </template>
              <div v-else class="bracket-col">
                <div class="bracket-match"></div>
              </div>
            </div>
            <div
              class="single-line"
              :class="{ active: activeFinalLoser }"
            ></div>
          </div>
        </div>
      </template>
      <template v-if="losers.length">
        <div class="de-bracket-header">
          <BracketSlider
            :key="bracketKey"
            :left-scroll="leftScroll"
            :rounds="losersRounds"
            :style="{ fontSize: `${fontSize}px` }"
            @scroll="onBracketScroll"
          >
            <template v-if="$scopedSlots.round" #round="{ round }">
              <slot name="round" :round="round" />
            </template>
          </BracketSlider>
        </div>
        <div class="design-line"></div>
        <div
          ref="bracketLosers"
          class="bracket-box double"
          @scroll="
            (event) => onBracketScroll(event.target.scrollLeft)
          "
        >
          <div
            :style="{ fontSize: `${fontSize}px` }"
            class="bracket double"
          >
            <div
              v-for="(round, index) in filteredLosers"
              :key="index"
              class="bracket-row"
              :class="{
                margin: filters.round.type !== 'bracket-bottom',
              }"
            >
              <template
                v-if="round.matches.length && !hideLosers(index)"
              >
                <div
                  v-for="(match, key) in round.matches"
                  :key="key"
                  class="bracket-col"
                >
                  <slot
                    name="match"
                    :match="{ ...match, activeTeamHash }"
                  >
                    <match-box
                      :match="match"
                      :active-hash="activeTeamHash"
                    />
                  </slot>
                  <div
                    class="line"
                    :class="{
                      active: activeTeamId === match.idWinner,
                    }"
                  ></div>
                </div>
              </template>
              <div v-else class="bracket-col">
                <div class="bracket-match"></div>
              </div>
            </div>
            <div class="double-line"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs';
import MatchBox from '@components/TournamentComponents/Brackets/BracketUI/MatchBox.vue';
import BracketSlider from '@components/TournamentComponents/Brackets/BracketUI/BracketSlider.vue';
import BracketZoom from '@components/TournamentComponents/Brackets/BracketUI/BracketZoom.vue';
import BracketSearch from '@components/TournamentComponents/Brackets/BracketUI/BracketSearch.vue';
import PlayerNextMatch from '@components/TournamentComponents/Brackets/BracketUI/PlayerNextMatch.vue';
import BracketEmbed from '@components/TournamentComponents/Brackets/BracketUI/BracketEmbed.vue';
import BracketPrintButton from '@components/TournamentComponents/Brackets/BracketUI/BracketPrintButton.vue';
import Select from '@components/v2/ui/Select.vue';

import { i18n } from '@src/localization/config';
import { fetchTeam } from '@src/shared/api/team';

export default {
  name: 'DoubleElimination',
  components: {
    PlayerNextMatch,
    BracketSearch,
    BracketZoom,
    BracketSlider,
    MatchBox,
    Select,
    BracketEmbed,
    BracketPrintButton,
  },
  props: {
    tournamentId: {
      type: [String, Number],
      required: true,
    },
    rounds: {
      type: [Object, Array],
      required: true,
    },
    controlsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hummerWinners: null,
      hummerLosers: null,
      leftScroll: 0,
      fontSize: 16,
      activeSlideNum: 0,
      maxActiveSlideNum: 6,
      activeTeamHash: '',
      activeTeamId: null,
      isFullSize: false,
      deOffsets: [],
      filters: {
        round: { id: 1, name: 'Вся сетка', type: 'all' },
      },
      bracketKey: 0,
    };
  },
  computed: {
    ...mapGetters('teams', ['getTeam']),

    filterOptions() {
      return [
        {
          id: 1,
          name: i18n.t('tournaments.allBracket'),
          type: 'all',
        },
        {
          id: 2,
          name: i18n.t('tournaments.winnersBracket'),
          type: 'bracket-top',
        },
        {
          id: 3,
          name: i18n.t('tournaments.losersBracket'),
          type: 'bracket-bottom',
        },
        ...this.rounds.winners
          .slice(1, this.rounds.winners.length - 2)
          .map((round, index) => ({
            id: round.id,
            name: round.name,
            type: 'round',
            sliceAmount: index + 1,
          })),
      ];
    },
    isHideManyColumns() {
      return (
        this.tournamentId === 18770 || this.tournamentId === 18768
      );
    },
    isHideOneColumn() {
      return this.tournamentId === 18769;
    },

    filteredWinners() {
      if (this.filters.round.type === 'round') {
        return this.rounds.winners.slice(
          this.filters.round.sliceAmount,
        );
      }
      return this.rounds.winners;
    },
    filteredLosers() {
      if (this.filters.round.type === 'round') {
        return this.rounds.losers.slice(
          this.filters.round.sliceAmount * 2,
        );
      }
      return this.rounds.losers;
    },

    winners() {
      if (this.filters.round.type !== 'bracket-bottom') {
        return this.filteredWinners || [];
      }

      return [];
    },
    winnersRounds() {
      const rounds = [];
      this.winners.forEach((round, index) => {
        if (index > 1) {
          rounds.push({}, round);
        } else {
          rounds.push(round);
        }
      });
      return rounds;
    },
    losers() {
      if (this.filters.round.type !== 'bracket-top') {
        return this.filteredLosers || [];
      }

      return [];
    },
    losersRounds() {
      if (this.filters.round.type !== 'bracket-bottom') {
        return [{}, ...this.losers, {}];
      }

      return [...this.losers, {}];
    },

    activeFinalLoser() {
      const winner =
        this.losers[this.losers.length - 1]?.matches[0]?.idWinner;
      return this.activeTeamId === winner;
    },
  },
  watch: {
    leftScroll(pos) {
      if (
        this.filters.round.type !== 'bracket-bottom' &&
        this.$refs.bracket
      ) {
        this.$refs.bracket.scrollLeft = pos;
      }
      if (
        this.filters.round.type !== 'bracket-top' &&
        this.$refs.bracketLosers
      ) {
        this.$refs.bracketLosers.scrollLeft = pos;
      }
    },
    fontSize() {
      this.leftScroll -= 1;
    },
    activeTeamHash: async function (newTeamHash) {
      const { team } = await fetchTeam(newTeamHash);
      this.activeTeamId = team?.id;
    },
  },
  mounted() {
    this.setHummerWinners();
    this.setHummerLosers();
  },
  beforeDestroy() {
    if (this.hammerWinners) {
      this.hammerWinners.destroy();
    }
    if (this.hammerLosers) {
      this.hammerLosers.destroy();
    }
  },
  methods: {
    async handleFilterInput() {
      this.leftScroll = 0;
      this.bracketKey += 1;
      await this.hammerWinners.destroy();
      await this.hammerLosers.destroy();
      if (this.$refs.bracket) {
        this.setHummerWinners();
      }
      if (this.$refs.bracketLosers) {
        this.setHummerLosers();
      }
    },
    onBracketScroll(pos, activeSlideNum, maxActiveSlideNum) {
      this.leftScroll = pos;
      if (
        activeSlideNum !== undefined &&
        this.activeSlideNum !== activeSlideNum
      ) {
        this.activeSlideNum = activeSlideNum;
      }
      if (
        maxActiveSlideNum !== undefined &&
        this.maxActiveSlideNum !== maxActiveSlideNum
      ) {
        this.maxActiveSlideNum = maxActiveSlideNum;
      }
    },
    setHummerWinners() {
      const wrapper = this.$refs.bracket;
      this.hammerWinners = new Hammer(wrapper);
      this.hammerWinners
        .get('pan')
        .set({ direction: Hammer.DIRECTION_HORIZONTAL });

      let pos = 0;
      this.hammerWinners.on('panstart', () => {
        pos = wrapper.scrollLeft;
      });

      this.hammerWinners.on('panleft panright', (ev) => {
        if (Math.abs(ev.deltaY) < 30) {
          wrapper.scrollLeft = pos - ev.deltaX;
        }
      });
    },
    setHummerLosers() {
      const wrapper = this.$refs.bracketLosers;
      this.hammerLosers = new Hammer(wrapper);
      this.hammerLosers
        .get('pan')
        .set({ direction: Hammer.DIRECTION_HORIZONTAL });

      let pos = 0;
      this.hammerLosers.on('panstart', () => {
        pos = wrapper.scrollLeft;
      });

      this.hammerLosers.on('panleft panright', (ev) => {
        if (Math.abs(ev.deltaY) < 30) {
          wrapper.scrollLeft = pos - ev.deltaX;
        }
      });
    },
    onFullSize(isFull) {
      this.isFullSize = isFull;
    },

    hideWinners(index) {
      const getValidIndexCount = (arr) =>
        arr.filter((item) => item.name !== undefined).length;
      const { startArr, endArr } = [...this.winnersRounds].reduce(
        (result, item, index) => {
          if (index < this.activeSlideNum) {
            result.startArr.push(item);
          }
          if (index < this.maxActiveSlideNum) {
            result.endArr.push(item);
          }
          return result;
        },
        { startArr: [], endArr: [] },
      );

      const startIndex = getValidIndexCount(startArr);
      const endIndex = getValidIndexCount(endArr);
      return index < startIndex || index >= endIndex;
    },

    hideLosers(index) {
      return (
        index < this.activeSlideNum - 1 ||
        index >= this.maxActiveSlideNum
      );
    },

    printPage() {
      const routeData = this.$router.resolve({
        name: 'bracket-print',
        params: {
          isMainLayout: false,
        },
        props: {
          id: Number(this.tournamentId),
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
$line-color: #454751;
$line-color-active: #929399;

.sticky {
  top: $headerHeight;
  background-color: $dark;

  &.single {
    padding: 12px 0;
  }

  &.double {
    &.active {
      ::v-deep .slide:nth-last-of-type(2):after {
        background-color: $line-color-active;
      }
    }

    ::v-deep .slide:nth-last-of-type(2) {
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        height: calc(100% + 12px);
        bottom: 0;
        right: em(-16px);
        width: 2px;
        background-color: $line-color;
      }
    }

    & ::v-deep {
      .slider-nav {
        margin-top: 12px;
        height: calc(100% - 12px);
      }

      .slider-wrapper {
        padding-top: 12px;
      }
    }
  }
}

.de {
  &.full {
    overflow: auto;
    background-color: $dark;

    .se-nav {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &-nav {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    align-items: center;
    @include max-tablet() {
      padding: 0 10px;
      flex-wrap: wrap;
      justify-content: flex-end;

      .bracket-search {
        width: 100%;
      }
    }
  }
}

.bracket-search {
  margin-right: auto;
}

.bracket {
  display: inline-flex;
  align-items: stretch;
  width: 100%;

  &-box {
    max-width: 100%;
    overflow: hidden;
    cursor: grab;
    @include min-tablet() {
      margin-right: 30px;
    }
    @include max-tablet() {
      margin-right: 10px;
    }

    &:active {
      cursor: grabbing;
    }
  }

  &.single {
    @include min-tablet() {
      margin-left: 30px;
    }
    @include max-tablet() {
      margin-left: 10px;
    }

    .bracket-row {
      &:nth-child(n + 3) {
        width: em(200px);
        margin-left: em(260px);
      }

      &:nth-child(n + 2):not(:nth-last-child(3)) {
        .line,
        .line:after {
          width: em(130px);
        }
      }

      &:nth-last-child(3) {
        .line {
          width: em(130px);
          height: 0;
          border-top: none;
          border-bottom: 2px solid;
          top: auto;
          bottom: 50%;

          &:after {
            top: 0;
            width: em(130px);
          }
        }
      }

      &:nth-last-child(2) {
        margin-top: em(40px);

        .line {
          display: none;
        }
      }
    }

    .single-line {
      position: relative;

      &.active:after {
        border-color: $line-color-active;
      }

      &:after {
        content: ' ';
        position: absolute;
        width: em(16px);
        top: 50%;
        height: 50%;
        right: em(200px);
        border-width: 2px 0 0 2px;
        border-style: solid;
        border-color: $line-color;
        margin-top: em(20px);
      }
    }
  }

  &.double {
    @include min-tablet() {
      margin-left: 30px;
    }
    @include max-tablet() {
      margin-left: 10px;
    }

    .bracket-col {
      position: relative;
    }

    .bracket-row {
      &.margin {
        &:first-child {
          margin-left: em(230px);
        }
      }

      &:nth-child(odd) {
        .line {
          height: em(20px);
          top: 50%;
          border-top: 2px solid;
          border-bottom: none;

          &:after {
            bottom: 0;
          }
        }
      }

      &:nth-last-child(2) {
        margin-right: em(230px);
        border-top: none;

        .line {
          bottom: 50%;
          top: auto;
          border-top: none;
          height: calc(50% + 12px);
          width: em(16px);
          border-bottom: 2px solid $line-color;

          &.active {
            border-color: $line-color-active;
          }

          &:after {
            display: none;
          }
        }
      }
    }

    .double-line {
      padding-right: 1px;
    }
  }

  &-row {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: em(200px);
    padding: 12px 0;

    & + & {
      margin-left: em(30px);
    }

    &:last-child {
      .bracket-col {
        margin: 0;
      }
    }

    // bracket-lines
    .line {
      color: $line-color;
      position: absolute;
      width: em(15px);
      height: 50%;
      border-right: 2px solid;
      left: 100%;

      &.active {
        color: $line-color-active;
      }

      &:after {
        content: ' ';
        position: absolute;
        left: 100%;
        width: em(15px);
        border-top: 2px solid;
        border-color: inherit;
      }
    }

    .bracket {
      &-col {
        position: relative;

        &:nth-child(odd) {
          .line {
            top: 50%;
            border-top: 2px solid;

            &:after {
              top: 100%;
            }
          }
        }

        &:nth-child(even) {
          .line {
            bottom: 50%;
            border-bottom: 2px solid;

            &:after {
              bottom: 100%;
            }
          }
        }
      }
    }
  }

  &-col {
    flex-grow: 1;
    flex-basis: em(80px);
    margin-bottom: em(40px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  &-match {
    height: em(80px);
    border-radius: 4px;
    flex-shrink: 0;
    padding: em(5px) 0 em(5px) em(5px);
    position: relative;
  }
}
</style>

<style lang="scss">
.hide-many-columns {
  .sticky.single {
    .slide:first-child,
    .slide:nth-child(2) {
      visibility: hidden;
    }
  }
  .bracket.single {
    .bracket-row:first-child,
    .bracket-row:nth-child(2) {
      visibility: hidden;
    }
  }
  .BracketSlider {
    .slide:nth-child(2) {
      visibility: hidden;
    }
  }
  .bracket.double {
    .bracket-row:first-child {
      visibility: hidden;
    }
  }
}

.hide-one-column {
  .sticky.single {
    .slide:first-child {
      visibility: hidden;
    }
  }
  .bracket.single {
    .bracket-row:first-child {
      visibility: hidden;
    }
  }
}

.de-bracket-header {
  position: sticky;
  z-index: 100;
  top: 0;
  padding: 24px 0;
  background: black;

  &:first-child {
    z-index: 101;
  }
  &:not(:first-child) {
    z-index: 100;
  }
}

.bracket-filter {
  max-width: 220px;
  width: 100%;
}

.bracket-controls {
  display: flex;
  gap: 12px;
}
</style>
